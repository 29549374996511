// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const advisorMenus = [
  {
    id: "portfolio",
    title: "PORTFOLIO",
    url: "/portfolio",
  },
  {
    id: "clients",
    title: "CLIENTS",
    url: "/clients",
  },
  {
    id: "chat",
    title: "CHAT",
    url: "/chat",
  },
  {
    id: "calendar",
    title: "CALENDAR",
    url: "/calendar",
  },
  {
    id: "email",
    title: "EMAIL",
    url: "/email",
  },
  {
    id: "commission",
    title: "COMMISSION REPORT",
    url: "/commission",
  },
];

export default advisorMenus;
