import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";

import Chart from "react-apexcharts";

import { roundNumber, decimalFormat } from "utils/common";
import useJwt from "utils/jwt/useJwt";
import { getData } from "store/actions/clients";

import Block from "ui-component/Block";
import TableRow from "ui-component/TableRow";
import { LoaderContext } from "utils/context/ProgressLoader";

const generateChartOptions = (theme, labels, totalValue=0) => {
  return {
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      theme.palette.common.gold,
      theme.palette.common.silver,
      theme.palette.common.goldBar,
      theme.palette.common.silverBar,
      theme.palette.common.black,
    ],
    legend: {
      show: false,
    },
    labels,
    plotOptions: {
      pie: {
        donut: {
          size: "85%",
          labels: {
            show: true,
            name: {
              color: "#ffffff",
            },
            value: {
              color: "#ffffff",
              formatter: function (val) {
                if (totalValue > 0) {
                  return (
                    roundNumber((parseFloat(val) * 100) / totalValue, 2) + " %"
                  );
                }
                else {
                  return roundNumber(val, 2) + " EUR";
                }
              },
            },
            total: {
              show: true,
              label: "Total",
              color: "#ffffff",
              formatter: function (w) {
                return "€ " + decimalFormat(totalValue, 2);
              },
            },
          },
        },
      },
    },
  }
}

const CommissionReport = (props) => {
  const theme = useTheme();

  const loaderContext = useContext(LoaderContext);
  const loadCommissionData = loaderContext.loadCommissionData;
  const commissionData = loaderContext.commissionData;

  const [monthChartOptions, setMonthChartOptions] = useState(generateChartOptions(theme, []));
  const [yearChartOptions, setYearChartOptions] = useState(generateChartOptions(theme, []));
  const [monthChartSeries, setMonthChartSeries] = useState([]);
  const [yearChartSeries, setYearChartSeries] = useState([]);
  const [monthCommissions, setMonthCommissions] = useState([]);
  const [yearCommissions, setYearCommissions] = useState([]);
  const [switchChecked, setSwitchChecked] = useState(false);

  useEffect(() => {
    loadCommissionData();
  }, []);

  useEffect(() => {
    if (!commissionData.commissions) {
      return;
    }

    let commissions = [];
    let labels = [];
    let currentBalances = [];

    for (let item of commissionData.commissions) {
      if (item.type === "Card") continue;
      let percent = null;
      if (commissionData.year_commission > 0) {
        percent = roundNumber(
          (parseFloat(item.year_commission) * 100) / commissionData.year_commission,
          2
        );
      }

      commissions.push({
        ...item,
        percent: percent ? percent : 0,
      });
      labels.push(item.type);
      currentBalances.push(roundNumber(item.year_commission, 2));
    }

    setYearCommissions(commissions);
    setYearChartOptions(generateChartOptions(theme, labels, commissionData.year_commission));
    setYearChartSeries(currentBalances);

    commissions = [];
    labels = [];
    currentBalances = [];

    for (let item of commissionData.commissions) {
      if (item.type === "Card") continue;

      let percent = null;
      if (commissionData.month_commission > 0) {
        percent = roundNumber(
          (parseFloat(item.month_commission) * 100) / commissionData.month_commission,
          2
        );
      }
      commissions.push({
        ...item,
        percent: percent ? percent : 0,
      });
      labels.push(item.type);
      currentBalances.push(roundNumber(item.month_commission, 2));
    }

    setMonthCommissions(commissions);
    setMonthChartOptions(generateChartOptions(theme, labels, commissionData.month_commission));
    setMonthChartSeries(currentBalances);
  }, [commissionData]);

  const handleSwitchChange = (event) => {
    setSwitchChecked(event.target.checked);
  }

  const getCardCurrency = () => {
    const card = commissionData.commissions.find((item) => item.type === "Card")
    return card ? card.currency : 'EUR'
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          <Box sx={{ flexGrow: 1 }}>
            <Block sx={{ outline: "none", borderTop: "solid 1px #202020" }}>
              <Typography component="p" variant="h1" sx={{ mb: 3, marginBottom: '50px' }}>
                COMMISSION REPORT
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                  my: "5px",
                }}
              >
                <Box sx={{ width: "120px", display: { xs: "none", sm: "block" } }}></Box>
                <Box sx={{ width: "150px", display: { xs: "none", sm: "block" } }}>Account Number</Box>
                <Box sx={{  width: "100px" }}>Metal Type</Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box sx={{ width: "150px" }}>Commission</Box>
                <Box sx={{ width: "120px" }}>Percent</Box>
              </Box>
              {switchChecked ? (
                <>
                {yearCommissions.map((commission) => (
                <TableRow
                  key={commission.type}
                  moneyType={commission.type}
                  account={commission.account_number}
                  amount={commission.year_commission}
                  currency={getCardCurrency()}
                  percentage={commission.percent}
                ></TableRow>
              ))}
                </>
              ) : (
                <>
                {monthCommissions.map((commission) => (
                <TableRow
                  key={commission.type}
                  moneyType={commission.type}
                  account={commission.account_number}
                  amount={commission.month_commission}
                  currency={getCardCurrency()}
                  percentage={commission.percent}
                ></TableRow>
                ))}</>
              )}

            </Block>
          </Box>

          <FormGroup sx={{marginTop: '0px', marginLeft: '2em'}}>
            <FormControlLabel control={<Switch checked={switchChecked} onChange={handleSwitchChange} />} label="This month / This year" />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper sx={{ height: "100%", position: "relative", p: 2, marginTop: '100px', backgroundColor: 'balck', background: 'black' }}>
            {!switchChecked ? (
              <Chart options={monthChartOptions} series={monthChartSeries} type="donut" />
            ) : (
              <Chart options={yearChartOptions} series={yearChartSeries} type="donut" />
            )}

            {/* <IconButton
              onClick={() => {
                navigate("/portfolio/graph");
              }}
              sx={{ position: "absolute", bottom: "10px", right: "10px" }}
            >
              <IconCirclePlus size={24} stroke={1} />
            </IconButton> */}
          </Paper>
        </Grid>
      </Grid>

    </>
  );
};

export default CommissionReport;
