import { useState } from "react";

import { Box, Link, LinearProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { decimalFormat } from "utils/common";

const supStyle = { color: "#585858", fontSize: "10px" };

const BalanceTableRow = ({ moneyType, switchChecked, amount, account, percentage, profitLoss, profitLossPercentage, currency }) => {
  const theme = useTheme();
  // const [isHovering, setHover] = useState(false);

  // const onMouseEnterHandler = (event)=>{
  //   setHover(true);
  // }
  // const onMouseLeaveHandler = (event)=>{
  //   setHover(false);
  // }
  // onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}
  const circleStyle = {
    bgcolor: "",
    border: "none",
  };
  const moneyTypeString = moneyType.toLowerCase();
  switch (moneyTypeString) {
    case "gold":
      circleStyle.bgcolor = theme.palette.common.gold;
      break;
    case "goldbar":
      circleStyle.bgcolor = theme.palette.common.goldBar;
      break;
    case "silver":
      circleStyle.bgcolor = theme.palette.common.silver;
      break;
    case "silverbar":
      circleStyle.bgcolor = theme.palette.common.silverBar;
      break;
    default:
      circleStyle.bgcolor = theme.palette.common.black;
      circleStyle.border = "solid 1px #ffffff";
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "40px",
          my: "5px",
        }}
      >
        <Box sx={{ width: "120px", display: { xs: "none", sm: "block" } }}>
          <Box
            sx={{ ...circleStyle, width: 20, height: 20, borderRadius: "50%" }}
          />
        </Box>
        { account && (
          <Box sx={{ width: "150px", display: { xs: "none", sm: "block" } }}>
            {account}
          </Box>
        )}
        <Box sx={{ width: "100px" }}>{moneyType}</Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        {switchChecked === undefined ? (
          <>
          <Box sx={{ width: "150px" }}>
            {decimalFormat(amount, 2) + " "}<sup style={{ ...supStyle }}>{currency}</sup>
          </Box>
          <Box sx={{ width: "120px", display: { xs: "none", sm: "block" } }}>
            {percentage.toFixed(2)}<sup style={{ ...supStyle }}>%</sup>
          </Box>
          </>
        ): (switchChecked ? (
          <>
          <Box sx={{ width: "150px" }}>
            {percentage.toFixed(2)}<sup style={{ ...supStyle }}>%</sup>
          </Box>
          <Box sx={{ width: "120px", display: { xs: "none", sm: "block" } }}>
            {profitLossPercentage}<sup style={{ ...supStyle }}>%</sup>
          </Box>
          </>
        ) : (
          <>
            <Box sx={{ width: "150px" }}>
              {decimalFormat(amount, 2) + " "}<sup style={{ ...supStyle }}>{currency}</sup>
            </Box>
            <Box sx={{ width: "120px", display: { xs: "none", sm: "block" } }}>
              {profitLoss + " "}<sup style={{ ...supStyle }}>{currency}</sup>
            </Box>
          </>
        ))}
      </Box>
      <LinearProgress
        variant="determinate"
        value={percentage}
        color="inherit"
        sx={{
          "& .MuiLinearProgress-bar": { transition: "none" },
          height: "1px",
        }}
      />
    </>
  );
};

export default BalanceTableRow;
